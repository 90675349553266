/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useSelector } from 'react-redux'


function SEO({ frUrl, enUrl, page, noIndex = false, newsroomPage = 0  }) {
  const lang = useSelector(state => state.lang)

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            descriptionEN
            baseUrl
            image
          }
        }
      }
    `
  )

  const defaults = data.site.siteMetadata
  const defaultsDescription = (lang === 'fr') ? defaults.description : defaults.descriptionEN
  const defaultsUrl = (lang === 'fr') ? defaults.baseUrl : `${defaults.baseUrl}en`

  const title = (page.title) ? page.title : defaults.title;
  const description = (page.description) ? page.description : defaultsDescription;
  const url = (page.path) ? `${defaults.baseUrl.slice(0,-1)}${page.path}` : defaultsUrl;
  const image = (page.image) ? page.image : defaults.image;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={`${url}${newsroomPage > 1 ? `/page/${newsroomPage}` : ''}`} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      {noIndex && <meta name="robots" content="noindex"></meta>}

      <meta name="google-site-verification" content="V9x1_O82jRwKuyDQh8USqCfY5EDPwfJSd4GZRC2D6w8" />

      { enUrl && <link rel="alternate" hreflang="fr" href={`${defaults.baseUrl.slice(0,-1)}${frUrl}${newsroomPage > 1 ? `/page/${newsroomPage}` : ''}`} /> } 
      { enUrl && <link rel="alternate" hreflang="en" href={`${defaults.baseUrl.slice(0,-1)}${enUrl}${newsroomPage > 1 ? `/page/${newsroomPage}` : ''}`} /> }
       
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={`https:${image}`}/>}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@Teregacontact" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={`https:${image}`} />}

      <script type="application/ld+raw">
        {`{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "${defaults.baseUrl}",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "${defaults.baseUrl.slice(0,-1)}/${lang === 'fr' ? 'recherche' : 'search'}?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }`}
      </script>
    </Helmet>
  )
}

export default SEO
