import React from 'react'
import { useInView } from 'react-intersection-observer'

import transformLink from '../../utils/transformLink'

import ButtonArrow from '../buttonArrow'
import ShapeImageIllu from '../shapeImageIllu'
import Wysiwyg from '../wysiwyg'


const ImageText = ({data, isOffer}) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})

  let theme = 'blue'
  let shape = 'pill'
  let isReversed = data.reversed

  //  get readable theme
  if (data.themeSolutions) {
    switch(data.themeSolutions) {
      case 'Dégradé Bleu':
        theme = 'gradient-blue'
        break
      case 'Dégradé Sienne':
        theme = 'gradient-sienne'
        break
      case 'Fond blanc - thème sienne':
        theme = 'sienne'
        break
      case 'Fond blanc - thème orange':
        theme = 'orange'
        break
      case 'Fond blanc - thème bleu':
        theme = 'blue'
        break
      default:
        theme = 'blue'
    }
  }

  // get shape for image
  if (data.imageShape) {
    shape = data.imageShape === 'Pillule' ? 'pill' : data.imageShape === 'Hexagone' ? 'hexagon-rotate2' : 'long-quad'
  }

  // get content + cta infos if one
  const content = data.content
  const cta = (data.cta) ? ((data.cta.fields) ? data.cta.fields : data.cta) : null
  let url

  if (cta) {
    let ctaContent = transformLink(cta, true, false)
    url = ctaContent.url
  }

  return (
    <div ref={ref} className={`${isOffer ? 'is-offer' : ''} ${inView ? 'has-anim' : ''} ${theme.includes('gradient') ? `has-full-bg img-text--gradient img-text--${theme}` : ''} img-txt`}>
      <div className="row items-center">
        {/* text */}
        <div className={`scroll-anim-el  ${isReversed ? 'scroll-anim-el--2' : ''} col-12 ${isOffer ? `md-col-7 lg-col-5 xl-col-4 ${isReversed ? 'xl-col-offset-1' : ''}` : 'xl-col-offset-1 md-col-7 lg-col-6 xl-col-5'} order-last mt-8 md:mt-0 ${!isReversed ? 'md:order-first' : ''}`}>
          <h2 className="title title-slice mb-5 md:mb-6">{data.title}</h2> 

          <Wysiwyg document={content} classes={`${cta ? 'mb-8' : ''}`} />

          { cta &&
            <ButtonArrow text={cta.label} link={url} color={theme === 'orange' ? 'orange' : 'night'} />
          }
        </div>
        
        {/* image */}
        <div className={`col-12 scroll-anim-el ${!isReversed ? 'scroll-anim-el--2' : ''} ${!isReversed && !isOffer ? 'lg-col-offset-1' : ''} ${isOffer ? `md-col-5 lg-col-4 ${!isReversed ? 'xl-col-offset-1' : ''}` : 'md-col-5 lg-col-5'}`}>
          { data.image &&
            <ShapeImageIllu image={data.image} theme={theme} shape={shape} isReversed={isReversed} />
          }
        </div>
      </div>
    </div>
  )
}

export default ImageText
