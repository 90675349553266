import React from 'react'
import { useSelector } from 'react-redux'

import twitterIcon from '../../icons/twitter.svg'
import linkedinIcon from '../../icons/linkedin.svg'
import roundShape from '../../icons/round-shape.svg'
import semiroundShape from '../../icons/semiround-shape.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'

const SocialCard = ({social, isSmall = false, seo = false}) => {
  const lang = useSelector(state => state.lang)
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  let icon, accountUrl
  let message = social.message

  if (!seo) {
    const content = document.createElement('div')
    content.innerHTML = message
    const quote = content.querySelector('.j-twitter-quote')

    if(quote) {
      content.removeChild(quote)
    }

    message = content.outerHTML
  }

  if (social.source.source === 'LinkedIn') {
    icon = linkedinIcon.url
    accountUrl = `https://www.linkedin.com/company/${social.source.term}`
  } else {
    icon = twitterIcon.url
    accountUrl = `https://www.twitter.com/${social.source.term}`
  }

  const smart_substr = (str, len) => {
    var temp = str.substr(0, len)
    if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
        temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')))
    }
    return temp
  }

  if (social.source.source === 'LinkedIn') {
    message = (message.length > 280) ? smart_substr(message, 279) + '&hellip;' : message
  }

  const socialTag = {
    linkedin: "linkedin",
    "x (twitter)" : "twitter"
  }

  return (
    <div className={`card social-card social-card--${socialTag[social.source.source.toLowerCase()]} ${isSmall ? 'is-small' : ''}`}>
      <a className="social-card__read-more" href={social.full_url} target="_blank" rel="noopener noreferrer"><span className="sr-only">Read more</span></a>
      <div className="social-card__header font-alternate">
        <div className="social-card__icon center">
          <svg className="icon-white" width="40" height="40">
            <use xlinkHref={icon} />
          </svg>
          <svg className="icon-night icon-bg" width="65" height="65">
            <use xlinkHref={hexagonShape.url} />
          </svg>
          <svg className="icon-beige icon-round" width="14" height="14">
            <use xlinkHref={roundShape.url} />
          </svg>
          <svg className="icon-aqua icon-semi" width="18" height="19">
            <use xlinkHref={semiroundShape.url} />
          </svg>
        </div>
        <div>
          <a className="font-bold" href={accountUrl} rel="noopener noreferrer" target="_blank">@{social.source.name !== null ? social.source.name : social.source.term}</a>
          <p className="mt-1 font-medium">{new Date(social.external_created_at).toLocaleDateString(lang, dateOptions)}</p>
        </div>
      </div>

      <div className="social-card__content" dangerouslySetInnerHTML={{__html: message}}></div>
    </div>
  )
}

export default SocialCard
