import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HomeLayout from '../components/layouts/home'
import Seo from '../components/seo'

function Home({data, pageContext}) {
  const path = pageContext.locale === 'fr' ? '/' : '/en'
  const homeDatas = data.homepage

  return (
    <Layout 
      frUrl="/"
      enUrl="/en"
      initLocale={pageContext.locale}
      headerType="bg-white"
      isHome={true}
      mainTag="home"
    >
      <Seo 
        frUrl="/"
        enUrl="/en"
        page={{
          image: (homeDatas.heroLargeImg) ? homeDatas.heroLargeImg.file.file.url : null,
          path: path
        }}/>

      <HomeLayout mainDatas={homeDatas} events={data.events.nodes} news={data.news.nodes} socials={data.socials.nodes} solutions={data.solutions.nodes} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomePage($locale: String!) {
    homepage: contentfulHomepageSolutions(node_locale: { eq: $locale }) {
      internal {
        type
      }
      heroTitleLine1
      heroTitleLine2
      heroText {
        heroText
      }
      heroText2 {
        heroText2
      }
      heroLargeImg {
        altImage
        file {
          file {
            url
          }
        }
      }
      heroSmallImg {
        altImage
        file {
          file {
            url
          }
        }
      }
      heroCta {
        pageLink {
          ... on Node {
            ... on ContentfulEvent {
              title
              node_locale
              internal {
                type
              }
            } 
            ... on ContentfulNews {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSolution {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulLanding {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulOffer {
              solution {
                title
              }
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHomepageSolutions {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulContact {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSimulator {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHub {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulPage {
              title
              slug
              customType
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulNewsroomList {
              title
              slug
              node_locale
              internal {
                type
              }
            }
          }
        }
        customLink
        label
        assetLink {
          file {
            url
          }
        }
      }
      heroCta2 {
        pageLink {
          ... on Node {
            ... on ContentfulEvent {
              title
              node_locale
              internal {
                type
              }
            } 
            ... on ContentfulNews {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSolution {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulLanding {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulOffer {
              solution {
                title
              }
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHomepageSolutions {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulContact {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSimulator {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHub {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulPage {
              title
              slug
              customType
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulNewsroomList {
              title
              slug
              node_locale
              internal {
                type
              }
            }
          }
        }
        customLink
        label
        assetLink {
          file {
            url
          }
        }
      }
      contentTitleLine1
      contentTitleLine2
      contentTxt {
        contentTxt
      }
      contentLargeImg {
        altImage
        file {
          file {
            url
          }
        }
      }
      contentSmallImg {
        altImage
        file {
          file {
            url
          }
        }
      }
      contentCTA {
        pageLink {
          ... on Node {
            ... on ContentfulEvent {
              title
              node_locale
              internal {
                type
              }
            } 
            ... on ContentfulNews {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSolution {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulLanding {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulOffer {
              solution {
                title
              }
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHomepageSolutions {
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulContact {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSimulator {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHub {
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulPage {
              title
              slug
              customType
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulNewsroomList {
              title
              slug
              node_locale
              internal {
                type
              }
            }
          }
        }
        customLink
        label
        assetLink {
          file {
            url
          }
        }
      }
      testimonyTitle
      testimonyTitle2
      testimonyItems {
        image {
          altImage
          file {
            file {
              url
            }
          }
        }
        content {
          content
        }
        author
        job
      }
      sliceImageTexte {
        internal {
          type
        }
        themeSolutions
        imageShape
        node_locale
        title
        content {
          raw
          references {
            __typename
            ... on ContentfulStyledText {
              contentful_id
              text
              type
            }
            ... on ContentfulNewsroomList {
              contentful_id
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulNews {
              contentful_id
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSolution {
              contentful_id
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulLanding {
              contentful_id
              title
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulOffer {
              solution {
                title
              }
              contentful_id
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulEvent {
              contentful_id
              title
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulContact {
              title
              contentful_id
              slug
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulSimulator {
              title
              slug
              contentful_id
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHub {
              title
              slug
              contentful_id
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulHomepageSolutions {
              title
              contentful_id
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulPage {
              contentful_id
              title
              slug
              customType
              node_locale
              internal {
                type
              }
            }
            ... on ContentfulAsset {
              contentful_id
              file {
                url
              }
            }
          }
        }
        proportions
        cta {
          pageLink {
            ... on Node {
              ... on ContentfulEvent {
                title
                node_locale
                internal {
                  type
                }
              } 
              ... on ContentfulNews {
                title
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulSolution {
                title
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulLanding {
                title
                slug
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulOffer {
                solution {
                  title
                }
                title
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulHomepageSolutions {
                title
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulContact {
                title
                slug
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulSimulator {
                title
                slug
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulHub {
                title
                slug
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulPage {
                title
                slug
                customType
                node_locale
                internal {
                  type
                }
              }
              ... on ContentfulNewsroomList {
                title
                slug
                node_locale
                internal {
                  type
                }
              }
            }
          }
          customLink
          label
          assetLink {
            file {
              url
            }
          }
        }
        image {
          altImage
          file {
            file {
              url
            }
          }
        }
        reversed
        theme
      }
    }
    events: allContentfulEvent(limit: 3, sort: {order: ASC, fields: startDate}, filter: {distributionChannel: {elemMatch: {title: {eq: "Terega solutions"}}}, fields: {isFuture: {eq: true}}, node_locale: {eq: $locale}, title: {ne: null}}) {
      nodes {
        title
        node_locale
        id
        internal {
          type
        }
        mainImage {
          altImage
          file {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        startDate
        endDate
      }
    }

    socials: allSocials(limit: 9, sort: {order: DESC, fields: external_created_at }, filter: {source: {source: {ne: "Facebook"}}, message: {ne: "dummy"}}) {
      nodes {
        message
        external_created_at
        source {
          source
          name
          term
        }
        full_url
      }
    }

    solutions: allContentfulSolution(filter: {distributionChannel: {elemMatch: {title: {eq: "Terega solutions"}}}, node_locale: {eq: $locale}, title: {ne: null}}, sort: {fields: title, order: ASC}) {
      nodes {
        title
        internal {
          type
        }
        solution
        node_locale
        mainImage {
          altImage
          file {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }

    news: allContentfulNews(filter: {distributionChannel: {elemMatch: {title: {eq: "Terega solutions"}}}, node_locale: {eq: $locale}, title: {ne: null}}, limit: 9, sort: {fields: publicationDate, order: DESC}) {
      nodes {
        title
        internal {
          type
        }
        node_locale
        publicationDate
        createdAt
        mainImage {
          altImage
          file {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Home
