import React from 'react'
import { useInView } from 'react-intersection-observer'

import Slider from '../slider'
import Image from '../image'

import roundShape from '../../icons/round-shape.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'

const HpTestimonies = ({ datas }) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})

  return (
    <section ref={ref} className={`slice hp-testimonies ${inView ? 'has-anim' : ''}`}>
      <h2 className="hp-testimonies__title full-width-left mb-6 md:mb-9 scroll-anim-el">
        { datas.testimonyTitle }
        { datas.testimonyTitle2 &&
          <><br/>{datas.testimonyTitle2}</>
        }
      </h2>

      <div className="relative hp-testimonies__wrapper">
        <svg className="icon-night absolute hp-testimonies__round" width="40" height="40">
          <use xlinkHref={roundShape.url} />
        </svg>
        <svg className="icon-gradient-sienne absolute hp-testimonies__hexagon" width="74" height="74">
          <use xlinkHref={hexagonShape.url} />
        </svg>

        <Slider 
          className="mt-6 md:mt-0 relative"
          name="hp-testimonies"
          hasPagination={true}
          nbItems={datas.testimonyItems.length}
          options = '{"effect": "fade"}'
          paginationClass="md-col-offset-4 lg-col-offset-5 xl-col-offset-6 xxl-col-offset-7 hp-testimonies__pagination">
        { datas.testimonyItems.map((item, i) => {
          item = (item.fields) ? item.fields : item
          
          return (
            <div className="row justify-between" key={i}>
              <div className="col-12 md-col-4 lg-col-5 scroll-anim-el scroll-anim-el--2">
                <Image
                  width="464"
                  height="566"
                  classes="hp-testimonies__image"
                  image={item.image} />
              </div>
              <div className="col-12 md-col-8 lg-col-7 xl-col-6 xxl-col-5 scroll-anim-el scroll-anim-el--3">
                <div className="md:pl-20 xl:pl-12 xxl:pl-0 pb-6">
                  <p className="hp-testimonies__content mt-8 md:mt-0">« { item.content.content ? item.content.content : item.content } »</p>

                  <div className="hp-testimonies__infos font-alternate mt-5">
                    <p className="hp-testimonies__author">{ item.author }</p>
                    <p className="hp-testimonies__job">{ item.job }</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        </Slider>
      </div>
    </section>
  )
}

export default HpTestimonies