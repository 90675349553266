
import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import slugify from 'slugify'

import Image from '../image'
import LinkIcon from '../linkIcon'

import semiroundShape from '../../icons/semiround-shape.svg'
import roundShape from '../../icons/round-shape.svg'

import trads from '../../intl/trads.json'

import transformLink from '../../utils/transformLink'

function getOrder(sol) {
  let order = 0;

  switch (sol.solution) {
    case 'Hydrogène':
      order = 1;
      break;
    case 'CO2':
      order = 2;
      break;
    case 'Méthanisation':
      order = 3;
      break;
    case 'Mobilité bas carbone':
      order = 4;
      break;
    case 'Digital':
      order = 5;
      break;
    default:
      order = 0;
  }

  return order;
}

const SolutionsPush = ({solutions, type="hp"}) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})
  
  const lang = useSelector(state => state.lang)
  const [activeItem, setActiveItem] = useState(slugify(solutions[0].fields ? solutions[0].fields.solution : solutions[0].solution).toLowerCase())

  return (
    <div ref={ref} className={`slice slice--bg solutions-push has-full-bg gradient-${activeItem} ${inView ? 'has-anim' : ''} ${type === 'inner' || type === 'landing' ? 'solutions-push--inner' : ''}`}>
      <h2 className="title title-small title--white solutions-push__title">
        {type === 'hp' || type === 'landing' ? trads[lang].solutions.titlePushHp : trads[lang].solutions.titlePush }
      </h2>

      <div className="row">
        <div className="col-12 md-col-7 lg-col-7 lg-col-offset-1">
          <ul className="md:-mb-8 flex md:block solutions-push__list scroll-anim-el">
            {solutions.sort((a, b) => {
              const orderA = getOrder(a);
              const orderB = getOrder(b);
              return orderA - orderB
            }).map((solution, i) => {
              const item = slugify(solution.fields ? solution.fields.solution : solution.solution).toLowerCase()
              const isActive = activeItem === item
              const ctaUrl =  transformLink(solution, false, false).url

              return (
                <li className="mr-6 md:mr-0 md:mb-8" key={i}>
                  {/* eslint-disable-next-line */}
                  <div className="solutions-push__link" onMouseEnter= {() => setActiveItem(item)}>
                    <span className={`solutions-push__link-name block ${isActive ? 'is-active' : ''}`}>{ trads[lang].solutions[item] }</span>
                    <LinkIcon 
                      link={ctaUrl} 
                      text={trads[lang].solutions.goTo} 
                      color="night" 
                      iconColor="night" 
                      className={`solutions-push__go solutions-push__go--desktop mt-4 ${isActive ? 'is-visible' : ''}`} />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`col-12 md-col-5 lg-col-4 flex items-center pb-14 md:pb-0 ${type === 'inner' ? 'md:-mt-22' : ''}`}>
          <div className={`relative solutions-push__wrap w-full solutions-push__wrap--${activeItem} scroll-anim-el scroll-anim-el--2`}>
            <svg className="absolute icon-semiround" width="60" height="78">
              <use xlinkHref={semiroundShape.url} />
            </svg>
            <svg className="absolute icon-round" width="53" height="53">
              <use xlinkHref={roundShape.url} />
            </svg>

            {solutions.map((solution, i) => {
              const ctaUrl =  transformLink(solution, false, false).url

              solution = solution.fields ? solution.fields : solution
              const item = slugify(solution.solution).toLowerCase()
              const isActive = activeItem === item

              return (
                <React.Fragment key={i}>
                  <Image
                    width="400"
                    height="464"
                    sizes={{"767": [265, 308], "1023": [380, 440] }}
                    classes={`shaped-image shaped-image--pill-left solutions-push__img ${isActive ? 'is-visible' : ''}`}
                    image={solution.mainImage} />
                  <LinkIcon 
                    link={ctaUrl} 
                    text={trads[lang].solutions.goTo} 
                    color="night" 
                    iconColor="night" 
                    className={`solutions-push__go mt-4 solutions-push__go--mobile ${isActive ? 'is-visible' : ''}`} />
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolutionsPush