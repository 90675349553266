import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'

import trads from '../../intl/trads.json'

import LinkIcon from '../linkIcon'

import calendarIcon from '../../icons/calendar.svg'

import transformLink from '../../utils/transformLink'

const EventCard = ({event, isSmall = false}) => {
  const lang = useSelector(state => state.lang)
  const url = transformLink(event, false, true).url

  // const date = eventDate(event.startDate, event.endDate, trads[lang].date.from, trads[lang].date.to, trads[lang].date.the, lang)
  const startDate = new Date(event.startDate)
  const endDate = new Date(event.endDate)
  
  return (
    <Link to={url} className={`event-card w-full card bg-night ${ isSmall ? 'is-small' : ''}`}>
      <span className="card__tag card__tag--event">{trads[lang].event}</span>
      <div className="flex-grow w-full">
        <div className="flex items-center event-card__date">
          <svg width="54" height="43" className="icon-white">
            <use xlinkHref={calendarIcon.url} />
          </svg>

          <div className="font-alternate font-bold">
            <p><span className="event-card__day">{ startDate.toLocaleDateString(lang, {day: 'numeric'}) }</span> { startDate.toLocaleDateString(lang, {month: 'long', year: 'numeric'}) } </p>
            {event.endDate &&
              <p className="mt-2"><span className="event-card__day">{ endDate.toLocaleDateString(lang, {day: 'numeric'}) }</span> { endDate.toLocaleDateString(lang, {month: 'long', year: 'numeric'}) } </p>
            }
          </div>
        </div>
        <h3 className="card__title card__title--big mb-8">{event.title}</h3>
      </div>
      <LinkIcon iconColor="purple" color="white" text={ trads[lang].learnMore } />
    </Link>
  )
}

export default EventCard
