import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'

import trads from '../../intl/trads.json'

import LinkIcon from '../linkIcon'
import Image from '../image'

import transformLink from '../../utils/transformLink'

const NewsCard = ({ news, isSmall = false }) => {
  news = news.page ? news.page : news

  const lang = useSelector(state => state.lang)
  const url = transformLink(news, false, true).url

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }
  const publicationDate = new Date(news.publicationDate).toLocaleDateString(lang, dateOptions)

  return (
    <Link to={url} className={`news-card card ${isSmall ? 'is-small' : ''}`}>
      <span className="card__tag card__tag--news">{trads[lang].news}</span>

      { news.mainImage ?
        <Image
          image={news.mainImage}
          width={isSmall ? 306 : 416}
          height={isSmall ? 243 : 330}
          classes="w-full card__img"
          sizes={isSmall ? {"415": [367, 292], "767": [720, 571], "1023": [460, 365], "1279": [400, 317] } : {"415": [367, 292], "767": [720, 571], "1023": [456, 362] }}
        />
        : <div className="card__empty">
            <img src="/img/logo.svg" alt="Terega Solutions" />
          </div>
      }
      <div className="news-card__content">
        <div className="news-card__title">
          <span className="text-label text-purple mb-2 block">{publicationDate}</span>
          <h3 className={`card__title ${ isSmall ? 'mb-5' : 'mb-8' }`}>{news.title}</h3>
        </div>
        <LinkIcon iconColor="orange" text={ trads[lang].learnMore } />
      </div>
    </Link>
  )
}

export default NewsCard
