import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'

import trads from '../intl/trads.json'

import ButtonArrow from './buttonArrow'
import Slider from './slider'
import EventCard from './cards/eventCard'
import NewsCard from './cards/newsCard'
import SocialCard from './cards/socialCard'
import GetNewsroomUrls from '../utils/getNewsroomUrls'

const NewsroomFeatured = ({ events, news, socials }) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})
  const lang = useSelector(state => state.lang)
  const newsroomUrls = GetNewsroomUrls()

  const [sliderItems, setSliderItems] = useState([])

  useEffect(() => {
    let sliderItems = []
    let newsIndex = 0
    let socialIndex = 0

    // for each "page" of slider
    for (var i = 0; i < 3; i++) {
      let tempNewsRs = []
      const newsItem = news[newsIndex]
      const eventItem = events[i]

      // push news
      if (newsItem) {
        tempNewsRs.push(newsItem)
        newsIndex++
      } else {
        if (socials[socialIndex]) {
          tempNewsRs.push(socials[socialIndex])
          socialIndex++
        } else {
          if (news[newsIndex]) {
            tempNewsRs.push(news[newsIndex])
            newsIndex++
          }
        }
      }

      // if no event push other news or RS
      if(!eventItem) {
        if (news[newsIndex]) {
          tempNewsRs.push(news[newsIndex])
          newsIndex++
        } else {
          if (socials[socialIndex]) {
            tempNewsRs.push(socials[socialIndex])
            socialIndex++
          }
        }
      }

      // push RS
      if (socials[socialIndex]) {
        tempNewsRs.push(socials[socialIndex])
        socialIndex++
      } else {
        if (news[newsIndex]) {
          tempNewsRs.push(news[newsIndex])
          newsIndex++
        }
      }

      tempNewsRs = tempNewsRs.sort((a,b) => new Date(b.publicationDate ? b.publicationDate : b.external_created_at) - new Date(a.publicationDate ? a.publicationDate : a.external_created_at))
      sliderItems = sliderItems.concat(tempNewsRs)

      // push event
      if (eventItem) {
        sliderItems.push(eventItem)
      }

      setSliderItems(sliderItems)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      { sliderItems.length > 0 &&
        <section className={`slice ${inView ? 'has-anim' : ''} `} ref={ref}>
          <h2 className="title title-slice slider-cards-title">
           { trads[lang].home.focusOn1 }
            <span className="font-normal block">{ trads[lang].home.focusOn2 }</span>
          </h2>
          
          <div className="slider-cards">
            <Slider
              name="newsroom-featured" 
              hasCards="true"
              nbItems={sliderItems.length}
              options = '{ "slidesPerView": 1, "spaceBetween": 24,"breakpoints": { "768": { "slidesPerView": 2, "slidesPerGroup": 2, "spaceBetween": 32 }, "1280": { "slidesPerView": 3, "slidesPerGroup": 3, "spaceBetween": 32 }} }'>

              {sliderItems.length > 0 && sliderItems.map((item, i) => {
                let itemCard 

                if (item !== undefined) {
                  if (item.internal !== undefined ) {
                    if (item.internal.type === 'ContentfulEvent') {
                      itemCard = <EventCard event={item} />
                    } else {
                      itemCard = <NewsCard news={item}  />
                    }
                  } else {
                    itemCard = <SocialCard social={item} />
                  }
                }

                return (
                  <div className={`scroll-anim-el w-full flex scroll-anim-el--${i + 1}`} key={i}>
                    { itemCard }
                  </div>
                )
              })}
            </Slider>
            
            <div className="scroll-anim-el scroll-anim-el--4">
              <ButtonArrow 
                text={ trads[lang].home.goNewsroom }
                link={`/${newsroomUrls['Hub'][lang]}`}
                color="night" />
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default NewsroomFeatured
