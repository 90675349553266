import React from 'react'
import HpHero from '../hp/hpHero'
import HpImageText from '../hp/hpImageText'
import HpTestimonies from '../hp/hpTestimonies'
import NewsroomFeatured from '../newsroomFeatured'
import ImageText from '../slices/imageText'
import SolutionsPush from '../solutions/solutionsPush'

const HomeLayout = ({mainDatas, news, events, socials, solutions}) => {
  return (
    <>
      <HpHero datas={mainDatas} />
      <HpImageText datas={mainDatas} />
      <SolutionsPush solutions={solutions} />
      <NewsroomFeatured news={news} events={events} socials={socials} />
      <HpTestimonies datas={mainDatas} />
      
      <div className="slice">
        <ImageText data={mainDatas.sliceImageTexte.fields ? mainDatas.sliceImageTexte.fields : mainDatas.sliceImageTexte } />
      </div>
    </>
  )
}

export default HomeLayout;