import React from 'react'
import { useInView } from 'react-intersection-observer'

import ButtonArrow from '../buttonArrow'
import Image from '../image'

import transformLink from '../../utils/transformLink'

const HpImageText = ({ datas }) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})
  const cta = datas.contentCTA ? datas.contentCTA.fields ? datas.contentCTA.fields : datas.contentCTA : null
  let ctaInfos = null

  if (cta) {
    ctaInfos = transformLink(cta, true, false)
  }

  return (
    <section  ref={ref} className={`slice hp-img-txt ${inView ? 'has-anim' : ''}`}>
      <div className="row">
        <div className="col-12 md-col-10 mx-auto">
          <h2 className="title title-slice title title-slice--big mb-9 scroll-anim-el">
            <span className="font-normal">{ datas.contentTitleLine1 }</span>
            <span className="block">{ datas.contentTitleLine2 }</span>
          </h2>
        </div>
      </div>

      <div className="row items-center">
        <div className="col-12 md-col-6 mb-8 md:mb-0 scroll-anim-el scroll-anim-el--2">
            <Image
              width="595"
              height="410"
              sizes={{"415": [341, 235], "767": [668, 461], "1023": [428, 295] }}
              classes="shaped-image shaped-image--pill-hor hp-img-txt__pill"
              image={datas.contentLargeImg} />
            
            <Image
              width="295"
              height="298"
              sizes={{"415": [169, 172], "767": [330, 333], "1023": [212, 215] }}
              classes="shaped-image shaped-image--hexagon-rotate2 hp-img-txt__hexagon"
              image={datas.contentSmallImg} />
        </div>

        <div className="col-12 md-col-6 lg-col-offset-1 lg-col-5 xl-col-4 scroll-anim-el scroll-anim-el--3">
          <p className="paragraph mb-9">
            { datas.contentTxt.contentTxt ? datas.contentTxt.contentTxt : datas.contentTxt }
          </p>

          { cta &&
            <ButtonArrow
              text={ cta.label }
              link={ ctaInfos.url }
              color="night"
              contentType={ ctaInfos.contentTypeContentful }
              external={ ctaInfos.external } />
          }
        </div>
      </div>
    </section>
  )
}

export default HpImageText