import React from 'react'
import { useInView } from 'react-intersection-observer'
import ButtonArrow from '../buttonArrow'

import transformLink from '../../utils/transformLink'
import Image from '../image'

import roundShape from '../../icons/round-shape.svg'
import semiroundShape from '../../icons/semiround-shape.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'

const HpHero = ({ datas }) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})
  const cta = datas.heroCta.fields ? datas.heroCta.fields : datas.heroCta
  const ctaInfos = transformLink(cta, true, false)

  const cta2 = datas.heroCta2.fields ? datas.heroCta2.fields : datas.heroCta2
  const cta2Infos = transformLink(cta2, true, false)

  return (
    <section ref={ref} className={`hp-hero hero ${inView ? 'has-anim' : ''}`}>
      {/* top */}
      <div className="row mb-10 md:mb-20 items-start">
        <div className="col-12 md-col-7 order-last md:order-first">
          <div className="relative">
            <svg className="icon-turquoise hp-hero__round-deco" width="126" height="126">
              <use xlinkHref={roundShape.url} />
            </svg>

            <h1 className="hp-hero__title mb-6 md:mb-10 scroll-anim-el">
              { datas.heroTitleLine1 }
              <span className="text-turquoise block">{ datas.heroTitleLine2 }</span>
            </h1>
            
            <div className="scroll-anim-el scroll-anim-el--2">
              <p className="paragraph-big hp-hero__intro mb-9">{ datas.heroText.heroText ? datas.heroText.heroText : datas.heroText }</p>

              <ButtonArrow
                text={ cta.label }
                link={ ctaInfos.url }
                color="night"
                contentType={ ctaInfos.contentTypeContentful }
                external={ ctaInfos.external } />
            </div>
          </div>
        </div>

        <div className="col-12 md-col-5 relative">
          <div className="hero__shape shaped-image shaped-image--hero">
            <Image
              width="938"
              height="860"
              isTop={true}
              classes="object-fit-image hero__img"
              sizes={{"415": [380, 415], "767": [685, 830], "1023": [515, 595], "1440": [610, 700] }}
              image={datas.heroLargeImg} />
          </div>
          
          <Image
            width="292"
            height="292"
            isTop={true}
            classes="hp-hero__quad shaped-image shaped-image--quad scroll-anim-el scroll-anim-el--3"
            sizes={{"415": [147, 147], "767": [290, 290], "1023": [209, 209]}}
            image={datas.heroSmallImg} />
          
          <svg className="icon-orange hp-hero__semiround" width="126" height="126">
            <use xlinkHref={semiroundShape.url} />
          </svg>
        </div>
      </div>

      {/* bottom */}
      <div className="row">
        <div className="col-12 md-col-6 lg-col-offset-1 lg-col-5 mb-6 md:mb-0">
          <div className="relative w-full hp-hero__bottom">
            <svg className="absolute icon-beige hp-hero__bottom-round" width="57" height="57">
              <use xlinkHref={roundShape.url} />
            </svg>
            <svg className="absolute icon-gradient-blue hp-hero__bottom-hexagon" width="200" height="200">
              <use xlinkHref={hexagonShape.url} />
            </svg>
            <svg className="absolute icon-sienne hp-hero__bottom-semiround" width="108" height="126">
              <use xlinkHref={semiroundShape.url} />
            </svg>
          </div>
        </div>
        <div className="col-12 md-col-6 lg-col-5 scroll-anim-el scroll-anim-el--4">
          <p className="paragraph-big mb-9">{ datas.heroText2.heroText2 ? datas.heroText2.heroText2  : datas.heroText2  }</p>

          <ButtonArrow
            text={ cta2.label }
            link={ cta2Infos.url }
            color="night"
            contentType={ cta2Infos.contentTypeContentful }
            external={ cta2Infos.external } />
        </div>
      </div>
    </section>
  )
}

export default HpHero
